@import '/styles/mixins.scss';
@import '/styles/variables.scss';

.container {
  @include flex(column, center, center);
  z-index: 2;

  &.loadingPage {
    @include size(100vw, 100vh);
    position: fixed;
    top: 0;
    left: 0;
    background-color: $lightblue;

    @include mobile {
      background: $blue;
    }

    .bubbles {
      svg {
        fill: $blue;

        @include mobile {
          fill: $lightblue;
        }
      }
    }
  }

  &.loadingComponent {
    @include size(100%, 100%);
    @include container($radius: $radius);
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba($lightgrey, 0.8);

    @include mobile {
      @include container($radius: 2.5rem 2.5rem 0 0);
    }

    .bubbles {
      svg {
        fill: $blue;
      }
    }
  }
}